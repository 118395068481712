import cameraIcon from './images/camera_icon.png';
import diaryIcon from './images/diary_icon.png';
import wrenchIcon from './images/wrench_icon.png';
import scrollIcon from './images/scroll_icon.png';
import mePhoto from './images/default_photo.jpg';
import duckFlying from './images/duck_flying.png';
import duckLaptop from './images/duck_laptop.gif';

const iconMap = new Map();
iconMap.set('diary', diaryIcon);
iconMap.set('camera', cameraIcon);
iconMap.set('wrench', wrenchIcon);
iconMap.set('scroll', scrollIcon);
iconMap.set('mePhoto', mePhoto);
iconMap.set('duckFlying', duckFlying);
iconMap.set('duckLaptop', duckLaptop);

export {iconMap};